<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
   <Loader v-model="loading" />
        <Panel header="Area de Entrega">
          <div class="formgrid grid p-fluid">
            <div class="col-12">
                <FormToolbar :actions="['new','save','delete']" @new="newDelivery" @save="saveDelivery" @delete="deleteDelivery" />
            </div>
            <FormInputText wrapperClass="field col-12 xl:col-6" label="Nombre" v-model="delivery_area.name" />
            <FormInputNumber wrapperClass="field col-12 xl:col-3" :label="'Precio'" v-model="delivery_area.price" :min="0" :minFractionDigits="2" :maxFractionDigits="2"></FormInputNumber>
            <FormInputNumber wrapperClass="field col-12 xl:col-3" :label="'Minutos Entrega'" v-model="delivery_area.minutes" :min="0" :minFractionDigits="0" :maxFractionDigits="0"></FormInputNumber>
          </div>
        </Panel>
    </div>
    <div class="col-12">
        <BasicDatatable :rows="delivery_areas" :selectionMode="'single'" :headers="headers" @selected="selectDelivery"/>
    </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputNumber from '../../../components/general/FormInputNumber.vue';

import formMixin from '../../../mixins/form.js'
import { delivery_area } from "../../../models/delivery_area";
import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid } from '../../../utilities/General';
export default {
  mixins: [formMixin],
  expose: ["refresh"],
  components: { FormInputNumber, FormInputText, FormToolbar, Loader, BasicDatatable },
  data() {
    return {
      loading: false,
      delivery_area: new delivery_area(),
      delivery_areas: [],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Precio", "price", { type: 'currency' }),
        new HeaderGrid("Minutos", "minutes"),
      ]
    };
  },
  methods: {
    selectDelivery(entity) {
      this.delivery_area = fillObject(this.delivery_area, entity);
    },
    newDelivery() {
      this.delivery_area = new delivery_area(this.session);
    },
    async deleteDelivery() { 
      this.loading = true;
      try {
        if (this.delivery_area.id) {
          await this.delivery_area.delete();
          this.delivery_areas = this.delivery_areas.filter(x => x.id != this.delivery_area.id);
          this.newDelivery();
          this.showSuccess("Se ha eliminado el area de entrega");
        }
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async saveDelivery() {
      this.loading = true;
      try {
        if (this.delivery_area.id) {
          let editedDelivery = await this.delivery_area.update();
          this.delivery_areas[this.delivery_areas.findIndex(x => x.id == this.delivery_area.id)] = editedDelivery;
        }else {
          var newDelivery = await this.delivery_area.save();
          this.delivery_areas.push(newDelivery);
        }
        this.newDelivery();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
    this.loading = true;
    this.delivery_area = new delivery_area(this.session);
    try {
      this.delivery_areas = await this.delivery_area.all();
    } catch (error) {
      this.showError(error);
    } finally {
      this.loading = false;
    }
  },
  },

};
</script>

<style>
</style>

